import React from 'react';
import ReferralsTable from 'material-table';
import { ThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { theme } from '../theme';
import { tableIcons } from '../table-icons';
import { urgentStyle } from './urgentStyle';
import moment from 'moment';
import { statusRenderer } from '../value-renderers';
import auth from '../../../utils/auth';

const ReferralsList = ({
  referrals,
  onReferralClick,
  showAllReferrals,
}) => {

  const columns = [
    {
      title: 'Urgent?',
      render: (referral) => {
        const { urgent } = referral;
        return <div style={urgentStyle(urgent)}>{urgent ? 'YES' : 'NO'}</div>
      },
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' }
    },
    {
      title: 'Referrer',
      field: 'referringOrganization',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' }
    },
    {
      title: 'Referee',
      field: 'receivingOrganization',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' }
    },
    {
      title: "Status",
      render: (referral) => (
        <div>{statusRenderer(referral.status, referral)}</div>
      ),
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' }
    },
    {
      title: 'Comment',
      render: (referral) => (
        <div>{referral.statusComment || '-----'}</div>
      ),
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' }
    },
    {
      title: 'Date/Time',
      render: (referral) => moment(referral.statusTs).format('LLL'),
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' }
    }
  ];

  if (showAllReferrals && auth.canSeeAllReferrals) {
    columns.splice(1, 0, {
      title: 'Patient',
      render: (referral) => {
        console.log('DEBUG: referral', referral)
        const { familyName, givenName, middleName} = referral?.patientDemographics;
        const fullName = `${givenName || ''} ${middleName || ''} ${familyName || ''}`
        return (
          <div>{fullName}</div>
        )
      },
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' }
    });

    columns.splice(1, 0, {
      title: 'MRN',
      render: (referral) => {
        console.log('DEBUG: referral', referral)
        const { receivingPatientId: mrn } = referral;
        return (
          <div>{mrn}</div>
        )
      },
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' }
    });

  }

  columns.splice(columns.length - 1, 0, {
    title: 'Description',
    field: 'description',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' }
  })

  if (referrals) {
    return (
      <ThemeProvider theme={theme}>
        <ReferralsTable
          title="" columns={columns} data={referrals} icons={tableIcons}
          onRowClick={(e, referral) => onReferralClick(referral)} options={{
          emptyRowsWhenPaging: true,
          minBodyHeight: '100%',
          maxBodyHeight: '100%',
          padding: 'dense',
          pageSize: 10,
          search: true,
        }}
        />
      </ThemeProvider>
    );
  }
  else {
    return (
      <ThemeProvider theme={theme}>
        <div>
          <CircularProgress/>
        </div>
      </ThemeProvider>
    );
  }
};

export default ReferralsList;
