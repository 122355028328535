import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory, useParams, withRouter } from "react-router-dom";
import ReferralsList from "./ReferralsList";
import {
  enableServiceDirectory,
  mockReferrals,
} from "../../../utils/constants";
import styles from "../../../styles/viewer.module.css";
import MockReferralsAPI from "../../../services/MockReferralsAPI";
import ReferralsAPI from "../../../services/ReferralsAPI";
import { Context } from "../../../store/AppStore";
import { ReferralActions, ReferralActionButton } from "./ReferralActions";
import ReferralDialog from "./ReferralDialog";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "../theme";
import { Container } from "@material-ui/core";
import auth from "../../../utils/auth";
import ServicesSelectionDialog from "./ServicesSelectionDialog";
import { getServicesConfig } from "../../../firebase/store";

const API = mockReferrals ? MockReferralsAPI : ReferralsAPI;

const ReferralsViewer = (props) => {
  const history = useHistory();
  const [, dispatch] = useContext(Context);
  const { patientId } = useParams();
  const [referrals, setReferrals] = useState(null);
  const [referralDialogOpen, setReferralDialogOpen] = useState(false);
  const [serviceCategories, setServiceCategories] = useState(null);
  const [organizationNames, setOrganizationNames] = useState(null);
  const [error, setError] = useState("");
  const [servicesSelectionDialogOpen, setServicesSelectionDialogOpen] =
    useState(false);
  const [servicesConfig, setServicesConfig] = useState(null);

  const showAllReferrals = props.showAllReferrals && auth.canSeeAllReferrals;

  const loadReferralData = useCallback(() => {
    ReferralsAPI.provisionUser(auth)
      .then(() => {
        if (!showAllReferrals) {
          loadReferralCatalogs();
        }
        API.getReferrals(props.showAllReferrals ? undefined : patientId)
          .then((referrals) => {
            setReferrals(referrals);
          })
          .catch((error) => {
            setError(error.message);
          });
      })
      .catch((error) => {
        setError(error.message);
      });
  }, [patientId, props.showAllReferrals, showAllReferrals]);

  const loadReferralCatalogs = () => {
    Promise.all([
      new Promise(async (resolve) => {
        resolve(await API.getServiceCategories(auth));
      }),
      new Promise(async (resolve) => {
        resolve(await API.getOrganizations(auth));
      }),
    ]).then(([categories, organizations]) => {
      setServiceCategories(categories);
      setOrganizationNames(organizations);
    });
  };

  useEffect(() => loadReferralData(), [patientId, loadReferralData]);

  useEffect(() => {
    if (enableServiceDirectory) {
      handleGetServicesConfig();
    }
  }, []);

  const handleGetServicesConfig = async () => {
    const conf = await getServicesConfig();
    console.log("DEBUG servicesConfig: ", conf);
    if (conf) {
      setServicesConfig(conf);
    }
  };

  const handleReferralClick = (referral) => {
    if (referral) {
      dispatch({ type: "UPDATE_REFERRAL", payload: referral });
      if (props.showAllReferrals) {
        history.push(`/referrals/${referral.referralId}`);
      } else {
        history.push(
          `/patient/search/${patientId}/info/1/referrals/${referral.referralId}`
        );
      }
    }
  };

  const handleNewReferralClick = () => {
    setReferralDialogOpen(true);
  };

  const handleReferralDialogClosed = () => {
    setReferralDialogOpen(false);
  };

  const handleServicesSelectionDialogClick = () => {
    setServicesSelectionDialogOpen(true);
  };

  const handleServicesSelectionDialogClosed = () => {
    setServicesSelectionDialogOpen(false);
  };

  const handleReferralSave = async (referral) => {
    referral.patientId = patientId;
    try {
      await API.createReferral(referral);
      await loadReferralData();
      setReferralDialogOpen(false);
      return "";
    } catch (error) {
      return error.message;
    }
  };

  if (error) {
    return (
      <ThemeProvider theme={theme}>
        <Container className={styles.errorContainer}>{error}</Container>
      </ThemeProvider>
    );
  }
  return (
    <Fragment>
      {serviceCategories && organizationNames && (
        <ReferralDialog
          open={referralDialogOpen}
          handleSave={handleReferralSave}
          handleClose={handleReferralDialogClosed}
          serviceCategories={serviceCategories}
          organizationNames={organizationNames}
        />
      )}
      {enableServiceDirectory && servicesConfig && (
        <ServicesSelectionDialog
          open={servicesSelectionDialogOpen}
          // handleSave={handleReferralSave}
          handleClose={handleServicesSelectionDialogClosed}
          config={servicesConfig}
          handleSave={handleReferralSave}
          // serviceCategories={serviceCategories}
          // organizationNames={organizationNames}
        />
      )}
      <div className={styles.documentViewer}>
        {referrals &&
          serviceCategories &&
          organizationNames &&
          !props.showAllReferrals && (
            <ReferralActions>
              <ReferralActionButton
                text="Add a Referral"
                action={handleNewReferralClick}
              />
              {enableServiceDirectory && servicesConfig && organizationNames ? (
                <ReferralActionButton
                  text="Search Directory"
                  action={handleServicesSelectionDialogClick}
                />
              ) : null}
            </ReferralActions>
          )}
        <ReferralsList
          showAllReferrals={showAllReferrals}
          referrals={referrals}
          onReferralClick={handleReferralClick}
        />
      </div>
    </Fragment>
  );
};

export default withRouter(ReferralsViewer);
