import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import ReferralsAPI from './ReferralsAPI';
import { getDocuments } from './MintAPI';
import { DocumentUtils } from './DocumentUtils';
import auth from '../utils/auth';
import { getPatientId } from './ReferralStates';

const shape = PropTypes.shape;
const string = PropTypes.string;
const number = PropTypes.number;
const bool = PropTypes.bool;
const object = PropTypes.object;
const arrayOf = PropTypes.arrayOf;

const REFERRAL_LINKED_DOCUMENT_SHAPE = shape({
  id: number,
  annotation: string,
  repositoryUniqueId: string.isRequired,
  documentUniqueId: string.isRequired,
  hcid: string.isRequired,
});

function idEquals(idType, attached, document) {
  const docIds = get(document, 'identifier', []);
  const idTypeDocId = docIds.find(id => get(id, 'type.text') === idType);
  const idTypeValue = get(idTypeDocId, 'value', '');
  return attached[idType] === idTypeValue;
}

export class ReferralUtils {

  static REFERRAL_DETAIL_SHAPE = shape({
    referralId: number.isRequired,
    referringOrganization: string.isRequired,
    referringPatientId: string.isRequired,
    referringMintUserId: string.isRequired,
    parentReferralId: string,
    receivingOrganizationName: string.isRequired,
    receivingMintUserId: string,
    receivingPatientId: string.isRequired,
    serviceCategory: string,
    urgent: bool.isRequired,
    rejected: bool.isRequired,
    deleted: bool.isRequired,
    createTs: string.isRequired,
    givenName: string.isRequired,
    middleName: string,
    familyName: string.isRequired,
    birthdate: string.isRequired,
    sex: string.isRequired,
    description: string.isRequired,
    status: string.isRequired,
    statusComment: string,
    statusTs: string.isRequired,
    addendum: arrayOf(object),
    notes: arrayOf(object),
    attachedMintIheDocuments: arrayOf(REFERRAL_LINKED_DOCUMENT_SHAPE),
    references: arrayOf(object),
  });

  static findLinkedDocuments(documents, referral) {
    return (referral?.attachedMintIheDocuments || [])
      .reduce(
        (foundDocuments, currentAttached) => {
          const foundDocument = (documents || []).find(
            document => {
              return (
                idEquals('repositoryUniqueId', currentAttached, document)
                && idEquals('documentUniqueId', currentAttached, document)
                && idEquals('hcid', currentAttached, document)
              )
            }
          );
          if (foundDocument) {
            foundDocuments.push(foundDocument)
          }
          return foundDocuments;
        },
        []
      );
  }

  static async getReferralWithDocuments(
    patientId,
    referralId,
    documents,
    referral,
  ) {
    if (!referral && !referralId) {
      throw new Error(
        'Please provide either the [referral] or the [referralId] parameter.');
    }
    const missingDataFetchers = [];
    const fetchedValues = {};
    if (!referral) {
      missingDataFetchers.push(new Promise(
        async (resolve) => {
          fetchedValues.referral = await ReferralsAPI.getReferral(referralId);
          resolve();
        }
      ));
    }
    if (!documents && patientId) {
      missingDataFetchers.push(new Promise(
        async (resolve) => {
          fetchedValues.documents = await getDocuments({ patientId });
          resolve();
        }
      ));
    }
    await Promise.all(missingDataFetchers);
    const _referral = fetchedValues.referral || referral
    let _documents = fetchedValues.documents || documents;
    if (!documents && !patientId) {
      _documents = await getDocuments({ patientId: getPatientId(auth, _referral) });
    }
    const _patientDocuments = DocumentUtils.normalizeDocuments(_documents);
    if (!_referral && referralId && !referral) {
      throw new Error(`Referral with id ${referralId} was not found.`);
    }
    if (!_patientDocuments && patientId && !documents) {
      throw new Error(`Documents for Patient ID '${patientId}' were not found.`);
    }
    return {
      referral: _referral,
      documents: _patientDocuments,
    }
  }

  static getRetrievalParameters(fhirDocument) {
    let retrievalParameters = {
      ...fhirDocument.identifier
        .filter(
          id => [
            'repositoryUniqueId',
            'documentUniqueId',
            'hcid',
          ].includes(get(id, 'type.text'))
        )
        .reduce(
          (params, id) => {
            params[get(id, 'type.text')] = id.value;
            return params;
          },
          {}
        ),
    };
    if (isEmpty(retrievalParameters)) {
      retrievalParameters = {
        documentUniqueId: fhirDocument.identifier[0].value,
        repositoryUniqueId: fhirDocument.identifier[1].value,
        hcid: fhirDocument.identifier[2].value,
      }
    }
    return retrievalParameters;
  }

  static patientDataToReferral(
    patientData, {
      urgent,
      serviceCategory,
      receivingOrganizationName,
      description,
    }
  ) {
    return {
      referringPatientId: get(patientData, 'fhirPatient.id', ''),
      givenName: get(patientData, 'fhirPatient.name[0].given', []).join(' '),
      familyName: get(patientData, 'fhirPatient.name[0].family', ''),
      birthdate: get(patientData, 'fhirPatient.birthDate', ''),
      sex: {'male': 'M', 'female': 'F'}[get(patientData, 'fhirPatient.gender')] || 'O',
      description,
      urgent,
      serviceCategory,
      referringOrganization: auth.user().userinfo.orgName,
      receivingOrganizationName,
      status: 'NEW',
      createTs: new Date(),
    }
  }

}
