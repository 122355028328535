import { get } from 'lodash';

function getOrganizationName(auth) {
  return get(auth, 'loggedInUser.userinfo.orgName');
}

export function isReferringOrganization(auth, referral) {
  console.log("DEBUG referral in isReferringOrganization: ", referral)
  return getOrganizationName(auth) === referral.referringOrganization;
}

export function isReferredOrganization(auth, referral) {
  return getOrganizationName(auth) === referral.receivingOrganizationName
}

export function getPatientId(auth, referral) {
  return isReferringOrganization(auth, referral)
    ? referral.referringPatientId
    : referral.receivingPatientId || referral.referringPatientId
}

function isReferringUser(auth, referral) {
  return (
    isReferringOrganization(auth, referral)
    && auth.mintUserId === referral.referringMintUserId
  );
}

function isPatientProvisioned(referral) {
  return referral.receivingPatientId;
}

function isOpen (referral) {
  return (
    [
      ReferralStates.DRAFT,
      ReferralStates.SUBMITTED,
      ReferralStates.ACTIVE,
      ReferralStates.ON_HOLD,
    ]
      .includes(referral.status)
  );
}

export default class ReferralStates {

  static DRAFT = 'draft';
  static SUBMITTED = 'submitted';
  static ACTIVE = 'active';
  static ON_HOLD = 'on-hold';
  static COMPLETED = 'completed';
  static REVOKED = 'revoked';

  static canWithdraw(auth, referral, patientInfo) {
    return (
      patientInfo
      && isReferringUser(auth, referral)
      && referral.status === ReferralStates.DRAFT
    );
  }

  static canAddNote(auth, referral, patientInfo) {
    return (
      patientInfo
      && isOpen(referral)
      && isPatientProvisioned(referral)
    );
  }

  static canAccept(auth, referral, patientInfo) {
    return (
      patientInfo
      && isReferredOrganization(auth, referral)
      && isPatientProvisioned(referral)
      && referral.status === ReferralStates.DRAFT
    )
  }

  static canReject(auth, referral) {
    return (
      isReferredOrganization(auth, referral)
      && isOpen(referral)
    )
  }

  static canComplete(auth, referral, patientInfo) {
    return (
      patientInfo
      && isReferredOrganization(auth, referral)
      && isPatientProvisioned(referral)
      && referral.status === ReferralStates.ADDRESSED
    )
  }

  static canProvision(auth, referral) {
    return (
      isReferredOrganization(auth, referral)
      && isOpen(referral)
      && !isPatientProvisioned(referral)
    )
  }

  static canAddDocument(auth, referral) {
    return isOpen(referral);
  }
}
