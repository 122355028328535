// import { referralsUrl } from "../utils/constants";
import { ErrorUtils } from "../utils/exceptions";
import { AxiosHelper } from "../utils/AxiosHelper";
import { getServicesConfig } from "../firebase/store";
import { enableServiceDirectory } from "../utils/constants";

const axiosInstance = ErrorUtils.configureAxiosForMintErrors(
  AxiosHelper.newInstance()
);

export default class FhirReferralsAPI {
  static baseUrl = null;

  static async setBaseUrl() {
    this.baseUrl = await this.getFhirBaseUrlFromFirebase();
  }

  static async getBaseUrl() {
    if (!this.baseUrl) {
      await this.setBaseUrl();
    }
    return this.baseUrl;
  }

  static async getFhirBaseUrlFromFirebase() {
    if (!enableServiceDirectory) return null;
    try {
      const conf = await getServicesConfig();
      if (conf?.connection?.baseUrl) {
        console.log("DEBUG servicesConfig: ", conf);
        return conf.connection.baseUrl;
      }
    } catch (error) {
      console.log(error);
    }
    return null;
  }

  static async getReferrals(
    patientId,
    status,
    modifiedSince,
    entireOrganization
  ) {
    console.log("DEBUG getReferrals patientId: ", patientId);
    const baseUrl = await this.getBaseUrl();
    const url = `${baseUrl}/ServiceRequest`;
    const params = new URLSearchParams();

    if (patientId) {
      params.append("patient.identifier", patientId);
      params.append("_include", "ServiceRequest:performer");
      params.append("_include", "ServiceRequest:requester");
      params.append("_include", "ServiceRequest:subject");
    }
    const result = await axiosInstance.get(url, {
      params,
    });
    console.log("DEBUG result referrals: ", result);
    // console.log("DEBUG getReferrals response data: ", result?.data);
    const referralsData = { referrals: [], organizations: [], patient: null };
    if (result?.data?.entry) {
      console.log("debug searching referrals")
      referralsData.referrals = result.data.entry
        .filter((r) => r?.resource?.resourceType === "ServiceRequest")
        .map((r) => r?.resource);
      referralsData.organizations = result.data.entry
        .filter((r) => r?.resource?.resourceType === "Organization")
        .map((r) => r?.resource);
      if (patientId) {
        referralsData.patient = await this.getPatientByIdentifier(patientId);
      }
    } else if (patientId) {
      console.log("debug searching patient")
      referralsData.patient = await this.getPatientByIdentifier(patientId);
    }
    // console.log("DEBUG referralsData: ", referralsData);
    console.log("DEBUG referralsData: ", referralsData);
    return referralsData;
  }

  static async getPatientByIdentifier(patientIdentifier) {
    console.log("DEBUG getPatientByIdentifier: ", patientIdentifier);
    try {
      const baseUrl = await this.getBaseUrl();
      const url = `${baseUrl}/Patient?identifier=${patientIdentifier}`;
      console.log("DEBUG patientUrl: ", url)
      const result = await axiosInstance.get(url);
      console.log("DEBUG result: ", result)
      const foundPatients = result.data.entry
        .filter((r) => r?.resource?.resourceType === "Patient")
        .map((r) => r?.resource);
      if (foundPatients && foundPatients.length > 0) {
        
        return foundPatients[0];
      }
    } catch (error) {
      console.log("DEBUG failed fetching patient")
      return null;
    }
    return null;
  }

  static async getReferral(referralId, includeRelated = false) {
    if (includeRelated) {
      const url = `${await this.getBaseUrl()}/ServiceRequest?_id=${referralId}&_include=ServiceRequest:subject&_include=ServiceRequest:performer`;
      const result = await axiosInstance.get(url);
      // console.log("DEBUG referralData: ", result?.data);
      const data = {
        referral: result?.data?.entry?.find(
          (d) => d?.resource?.resourceType === "ServiceRequest"
        ),
        patient: result?.data?.entry?.find(
          (d) => d?.resource?.resourceType === "Patient"
        ),
        organizations: result?.data?.entry
          ?.filter((d) => d?.resource?.resourceType === "Organization")
          .map((r) => r?.resource),
      };
      return data;
    } else {
      const url = `${await this.getBaseUrl()}/ServiceRequest/${referralId}`;
      const result = await axiosInstance.get(url);
      // console.log("DEBUG referralData: ", result?.data);
      return result?.data;
    }
  }

  static async getOrganizations() {
    const url = `${await this.getBaseUrl()}/Organization`;
    const params = {};
    // if (patientId) {
    //   params["patient.identifier"] = patientId;
    //   params["_include"] = "ServiceRequest:performer";
    // }
    const result = await axiosInstance.get(url, {
      params,
    });
    // console.log("DEBUG getOrganizations response data: ", result?.data);
    const data = { organizations: [] };
    if (result?.data) {
      data.organizations = result.data.entry
        .filter((r) => r?.resource?.resourceType === "Organization")
        .map((r) => r?.resource);
    }
    return data;
  }

  static async createReferral(payload) {
    const url = `${await this.getBaseUrl()}/ServiceRequest`;
    const result = await axiosInstance.post(url, payload);
    console.log("DEBUG createReferral response data: ", result?.data);
    return result?.data;
  }

  static async getServiceCategories() {
    const url = `${await this.getBaseUrl()}/ValueSet/ocp-service-category`;
    const params = {};
    // if (patientId) {
    //   params["patient.identifier"] = patientId;
    //   params["_include"] = "ServiceRequest:performer";
    // }
    const result = await axiosInstance.get(url, {
      params,
    });
    // console.log("DEBUG getServiceCategories response data: ", result?.data);
    const data = { serviceCategories: [] };
    if (result?.data?.compose?.include?.[0]?.concept) {
      data.serviceCategories = result.data.compose.include[0].concept;
    }
    return data;
  }
}
