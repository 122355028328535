import React from "react";
import moment from "moment";
import { urgentStyle } from "./Referrals/urgentStyle";

export const commentRenderer = (str) => str || "-----";
export const upperCaseRenderer = (str) =>
  typeof str === "string" ? str.toUpperCase() : str;
export const statusRenderer = (status, referral) => {
  const _status = upperCaseRenderer(status);
  if (_status === "COMPLETED" && referral.rejected) {
    return <span style={urgentStyle(true)}>REJECTED</span>;
  }
  return _status;
};
export const dateTimeRenderer = (timestampStr) =>
  moment(timestampStr).format("LLL");
export const yesNoRenderer = (booleanValue) => (booleanValue ? "YES" : "NO");
