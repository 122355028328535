import React, { useEffect, useState } from "react";
import FhirReferralsAPI from "../../../services/FhirReferralsAPI";

const API = FhirReferralsAPI;

function useReferrals({
  patientId,
  showAllReferrals,
  individual = false,
  referralId = null,
}) {
  const [referrals, setReferrals] = useState([]);
  const [referral, setReferral] = useState(null);
  const [patient, setPatient] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [error, setError] = useState([]);

  useEffect(() => {
    if (individual && referralId) {
      manageGetReferral();
      manageGetServiceCategories();
    } else {
      if (showAllReferrals !== undefined) {
        manageGetReferrals();
        manageGetServiceCategories();
      }
    }
  }, [patientId, showAllReferrals, individual, referralId]);

  const manageGetReferrals = async () => {
    console.log("DEBUG manageGetReferrals patientId: ", patientId);
    try {
      const responseReferrals = await API.getReferrals(
        showAllReferrals ? undefined : patientId
      );
      console.log("DEBUG responseReferrals: ", responseReferrals);
      if (responseReferrals?.referrals) {
        setReferrals(responseReferrals.referrals);
      }
      if (responseReferrals?.referrals) {
        setOrganizations(responseReferrals.organizations);
      }
      if (responseReferrals?.patient && patientId) {
        setPatient(responseReferrals.patient);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const manageGetReferral = async () => {
    try {
      const responseReferral = await API.getReferral(referralId, true);
      // console.log("DEBUG responseReferral: ", responseReferral);
      if (responseReferral?.patient?.resource) {
        setPatient(responseReferral.patient.resource);
      }
      if (responseReferral?.referral?.resource) {
        setReferral(responseReferral.referral.resource);
      }
      if (responseReferral?.organizations) {
        setOrganizations(responseReferral.organizations);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const manageCreateReferral = async (payload) => {
    try {
      const response = await API.createReferral(payload);
      console.log("DEBUG saveReferral response: ", response);
      manageGetReferrals();
      return response?.data;
    } catch (error) {
      setError(error.message);
    }
  };

  const manageGetServiceCategories = async () => {
    try {
      const response = await API.getServiceCategories();
      if (response?.serviceCategories) {
        setServiceCategories(response.serviceCategories);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return {
    referral,
    referrals,
    organizations,
    patient,
    serviceCategories,
    createReferral: manageCreateReferral,
    error,
  };
}

export default useReferrals;
