import axios from "axios";
import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from "prop-types";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import colors from "../../../utils/colors";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  TextField,
} from "@material-ui/core";
import { isArray } from "lodash";
import ServicesTable from "./ServicesTable";
import Refer from "./Refer";
import Autocomplete from "@material-ui/lab/Autocomplete";

const theme = createMuiTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        left: "48%",
        position: "absolute",
        bottom: "10px",
      },
      svg: {
        color: colors.baseBlue,
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    // minWidth: 500,
  },
  checkBox: {
    margin: theme.spacing(1),
    marginBottom: 0,
  },
  right: {
    marginLeft: "auto",
  },
  cancelButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: "auto",
  },
  searchButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: "auto",
  },
}));

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

const getIdFromReference = (reference) => {
  return reference.split("/")[1];
};

const getServices = async (
  url,
  {
    serviceType,
    addressPostalCode,
    addressState,
    orgName,
    serviceCategory,
    language,
  },
  capabilities
) => {
  const params = {};
  console.log("DEBUG capabilities: ", capabilities);
  if (capabilities?.IncludeAll) {
    params["_include"] = "*";
  }
  if (serviceType) {
    params["service-type"] = serviceType;
  }
  if (serviceCategory) {
    params["service-category"] = serviceCategory;
  }
  if (addressPostalCode) {
    params["address-postalcode"] = addressPostalCode;
  }
  if (addressState) {
    params["address-state"] = addressState;
  }
  if (orgName) {
    params["organization:Organization.name"] = orgName;
  }
  if (language) {
    params["language"] = language;
  }

  const response = await axiosInstance.get(url, {
    params,
  });
  return response?.data;
};

const getServiceTypes = async (url) => {
  if (url) {
    const result = await axiosInstance.get(url);
    return result?.data;
  }
  return [];
};

const parseBundleToServices = (bundle, mapping) => {
  if (bundle?.entry && isArray(bundle.entry)) {
    const locationResources = bundle.entry
      .filter((r) => r?.resource?.resourceType === "Location")
      .map((r) => r?.resource);
    const organizationResources = bundle.entry
      .filter((r) => r?.resource?.resourceType === "Organization")
      .map((r) => r?.resource);

    const healthcareServiceResources = bundle.entry
      .filter((serv) => serv?.resource?.resourceType === "HealthcareService")
      .map((serv) => {
        const location = locationResources.find((loc) => {
          if (serv?.resource?.location?.[0]?.reference) {
            return (
              getIdFromReference(serv.resource.location[0].reference) === loc.id
            );
          }
          return false;
        });
        const organization = organizationResources.find((org) => {
          if (serv?.resource?.providedBy?.reference) {
            return (
              getIdFromReference(serv.resource.providedBy.reference) === org.id
            );
          }
          return false;
        });

        let organizationInSystem = null;
        if (organization?.name && mapping) {
          console.log("DEBUG mapping object retrieved");
          if (
            mapping.hasOwnProperty(organization?.name) &&
            mapping[organization?.name]
          ) {
            console.log(
              `DEBUG Organization ${organization?.name} has a mapping`
            );
            const mappedName = mapping[organization.name];
            if (mappedName) {
              console.log(`DEBUG mappedName: ${mappedName}`);
              organizationInSystem = {
                name: mappedName,
              };
            }
            // const organizationInSystem = {
            //   id: '',
            //   name: ''
            // }
          }
        }

        return {
          healthcareService: serv?.resource,
          location,
          organization,
          organizationInSystem,
        };
      });

    console.log(
      "DEBUG: healthcareServiceResources: ",
      healthcareServiceResources
    );
    console.log("DEBUG: locationResources: ", locationResources);
    console.log("DEBUG: organizationResources: ", organizationResources);

    return healthcareServiceResources;
  }
  return [];
};

function ServicesSelectionDialog({ open, handleClose, config, handleSave }) {
  const classes = useStyles();
  const [serviceTypeSearch, setServiceTypeSearch] = useState("");
  const [serviceCategorySearch, setServiceCategorySearch] = useState("");
  const [addressPostalCodeSearch, setAddressPostalCodeSearch] = useState("");
  const [addressStateSearch, setAddressStateSearch] = useState("");
  const [languageSearch, setLanguageSearch] = useState("");
  const [orgNameSearch, setOrgNameSearch] = useState("");
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([
    "Child/Adolescent",
    "Adult",
    "Individual Counseling",
    "Child Care",
    "Parenting Skills",
    "General Counseling Services",
    "Parenting Skills Classes",
    "Adapted Toys",
    "Water Courts",
  ]);
  const [addressStates, setAddressStates] = useState(["MI"]);
  const [addressPostalCodes, setAddressPostalCodes] = useState([
    "49441",
    "49038",
  ]);
  const [languages, setLanguages] = useState(["English", "Spanish"]);
  const [orgNames, setOrgNames] = useState([]);

  useEffect(() => {
    if (config?.connection?.serviceTypesUrl) {
      handleGetServiceTypes();
    }
  }, []);

  useEffect(() => {
    console.log("DEBUG: serviceTypes: ", serviceTypes);
  }, [serviceTypes]);

  const canCreateReferral = () => {
    return !!selectedService?.organizationInSystem?.name;
  };

  const handleGetServiceTypes = async () => {
    const result = await getServiceTypes(config?.connection?.serviceTypesUrl);
    console.log("DEBUG result: ", result);
    if (
      result &&
      result?.compose?.include?.[0]?.concept &&
      result?.compose?.include?.[0]?.concept?.length > 0
    ) {
      console.log(
        "DEBUG setting service types to: ",
        result.compose.include[0].concept
      );
      const filtered = result.compose.include[0].concept.filter(
        (v, i, a) => a.findIndex((v2) => v.code === v2.code) === i
      );
      setServiceTypes(filtered);
      // setServiceTypes(result.compose.include[0].concept);
    } else {
      setServiceTypes(result);
    }
  };

  const handleResetAndClose = () => {
    setServiceTypeSearch("");
    setServiceCategorySearch("");
    setLanguageSearch("");
    setAddressPostalCodeSearch("");
    setAddressStateSearch("");
    setSelectedService(null);
    setOrgNameSearch("");
    handleClose();
  };

  const handleServiceTypeSearchChange = (event, newInputValue) => {
    setServiceTypeSearch(newInputValue);
  };

  const handleServiceCategorySearchChange = (event, newInputValue) => {
    setServiceCategorySearch(newInputValue);
  };

  const handleLanguageSearchChange = (event, newInputValue) => {
    setLanguageSearch(newInputValue);
  };

  const handleAddressPostalCodeSearchChange = (event, newInputValue) => {
    setAddressPostalCodeSearch(newInputValue);
  };

  const handleAddressStateSearchChange = (event, newInputValue) => {
    setAddressStateSearch(newInputValue);
  };

  const handleOrgNameSearchChange = (event, newInputValue) => {
    setOrgNameSearch(newInputValue);
  };

  const handleSearchServices = async (event) => {
    event.preventDefault();
    setIsSearching(true);
    setServices([]);
    const searchParams = {
      serviceType: serviceTypeSearch,
      serviceCategory: serviceCategorySearch,
      addressPostalCode: addressPostalCodeSearch,
      addressState: addressStateSearch,
      orgName: orgNameSearch,
      language: languageSearch,
    };
    const servicesResponse = await getServices(
      config?.connection?.servicesUrl,
      searchParams,
      config?.capabilities
    );
    if (servicesResponse) {
      setServices(parseBundleToServices(servicesResponse, config?.mintMapping));
    }
    setIsSearching(false);
  };

  return (
    <>
      <Dialog
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        aria-labelledby="services-dialog"
        scroll="paper"
      >
        <DialogTitle id="services-dialog-title">Services Selection</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSearchServices}>
            <FormGroup row>
              <Autocomplete
                freeSolo
                options={serviceTypes.map((s) => s?.code)}
                variant="outlined"
                margin="dense"
                label="Type"
                fullWidth
                inputValue={serviceTypeSearch}
                onInputChange={handleServiceTypeSearchChange}
                renderInput={(params) => <TextField {...params} label="Type" />}
              />
            </FormGroup>

            <FormGroup row>
              <Autocomplete
                freeSolo
                options={serviceCategories}
                variant="outlined"
                margin="dense"
                label="Category"
                fullWidth
                inputValue={serviceCategorySearch}
                onInputChange={handleServiceCategorySearchChange}
                renderInput={(params) => (
                  <TextField {...params} label="Category" />
                )}
              />
            </FormGroup>

            {config?.capabilities?.HealthcareServiceByOrganizationName ? (
              <FormGroup row>
                <Autocomplete
                  freeSolo
                  options={orgNames}
                  variant="outlined"
                  margin="dense"
                  label="Org Name"
                  fullWidth
                  inputValue={orgNameSearch}
                  onInputChange={handleOrgNameSearchChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Org Name" />
                  )}
                />
              </FormGroup>
            ) : null}

            <FormGroup row>
              <Autocomplete
                freeSolo
                options={addressStates}
                variant="outlined"
                margin="dense"
                label="State"
                fullWidth
                inputValue={addressStateSearch}
                onInputChange={handleAddressStateSearchChange}
                renderInput={(params) => (
                  <TextField {...params} label="State" />
                )}
              />
            </FormGroup>

            <FormGroup row>
              <Autocomplete
                freeSolo
                options={addressPostalCodes}
                variant="outlined"
                margin="dense"
                label="Postal Code"
                fullWidth
                inputValue={addressPostalCodeSearch}
                onInputChange={handleAddressPostalCodeSearchChange}
                renderInput={(params) => (
                  <TextField {...params} label="Postal Code" />
                )}
              />
            </FormGroup>

            {config?.capabilities?.HealthcareServiceByLanguage ? (
              <FormGroup row>
                <Autocomplete
                  freeSolo
                  options={languages}
                  variant="outlined"
                  margin="dense"
                  label="Language"
                  fullWidth
                  inputValue={languageSearch}
                  onInputChange={handleLanguageSearchChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Language" />
                  )}
                />
              </FormGroup>
            ) : null}

            <FormGroup row>
              <Button
                color="primary"
                type="submit"
                disabled={isSearching}
                className={classes.searchButton}
              >
                Search
              </Button>
            </FormGroup>
          </form>
          {isSearching && (
            <ThemeProvider theme={theme}>
              <CircularProgress />
            </ThemeProvider>
          )}
          <ServicesTable
            services={services}
            selectedService={selectedService}
            setSelectedService={setSelectedService}
          />
          {selectedService && canCreateReferral() && (
            <Refer
              service={selectedService}
              handleSave={handleSave}
              handleClose={handleResetAndClose}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.cancelButton}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ServicesSelectionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  config: PropTypes.object,
  handleSave: PropTypes.func.isRequired,
};

export default ServicesSelectionDialog;
