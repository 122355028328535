import { get } from 'lodash';
import moment from 'moment';
import { calculateAge } from './constants';

const MINT_TO_FHIR_GENDERS = {
  'M': 'male',
  'F': 'female',
  'O': 'other',
  '': 'unknown',
}

export function convertReferralToDisplayPatient(referral) {
  // const { demographics } = referral;
  // console.log("DEBUG convertReferralToDisplayPatient referral: ", referral)
  // let bdate = get(demographics, 'birthdate', '')
  // bdate = moment(new Date(bdate)).format("ll")
  // bdate = bdate === 'Invalid date' ? '' : bdate
  // let lastUpdate = get(demographics, 'mintUpdateTs', '')
  // lastUpdate = moment(new Date(lastUpdate)).format("ll")
  // lastUpdate = lastUpdate === 'Invalid date' ? '' : lastUpdate
  // const city = get(demographics, 'city', '')
  // const state = get(demographics, 'state', '')
  // const { homePhone, businessPhone } = demographics;

  const displayPatient = {};

  displayPatient['id'] = null
  // displayPatient['firstName'] = get(demographics, 'givenName', '')
  // displayPatient['lastName'] = get(demographics, 'familyName', '')
  // displayPatient['birthDate'] = bdate
  // displayPatient['age'] = calculateAge(bdate)
  // displayPatient['location'] = city ? `${city}, ${state}` : ''
  // displayPatient['gender'] = MINT_TO_FHIR_GENDERS[get(demographics, 'sex', '')]
  // displayPatient['lastUpdate'] = lastUpdate
  // displayPatient['address'] = get(demographics, 'streetAddress', '')
  // displayPatient['phone'] = homePhone || businessPhone || ''
  // displayPatient['deceased'] = get(demographics, 'deathIndicator', 'N/A')

  return displayPatient;

}
