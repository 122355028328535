import moment from "moment";

export const getDateTime = (referral) => {
  const formattedDateTime = referral?.occurrenceDateTime
    ? moment(referral.occurrenceDateTime)
    : null;
  return formattedDateTime?.format("LLL");
};

export const getIdFromReference = (reference) => {
  return reference.split("/")[1];
};

const getOrganizationFromReference = (reference, organizations, category) => {
  // console.log("DEBUG reference: ", reference);
  // console.log("DEBUG organizations: ", organizations);
  const organizationId = reference ? getIdFromReference(reference) : null;

  if (organizationId) {
    const organization = organizations.find(
      (org) => org?.id === organizationId
    );
    if (organization) {
      // console.log("DEBUG organization: ", organization);
      return organization;
    }
  }

  return null;
};

export const getReferrer = (referral, organizations) => {
  return getOrganizationFromReference(
    referral?.requester?.reference,
    organizations
  );
};

export const getReferee = (referral, organizations) => {
  return getOrganizationFromReference(
    referral?.performer?.[0]?.reference,
    organizations
  );
};

export const getDescription = (referral) => {
  let description = "";
  if (referral?.note?.[0]?.text) {
    description = referral.note[0].text;
  }
  return description;
};
